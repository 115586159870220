//import CompletionItemProvider from 'o365.controls.monaco.CompletionItemProvider.js';
import { babelParser, babelTraverse } from 'app-dependency-parser';
import { getLibUrl } from 'o365.modules.helpers.js';
import Api from 'o365.modules.data.api.ts';
import {cdnBaseUrl} from 'o365.modules.helpers.js';
import { userSession } from 'o365.modules.configs.ts';
import { localStorageHelper } from 'o365.modules.StorageHelpers.ts';

const featureFlagEnabledForUser = [
    // 4178,
    102028,
    // 113504
].includes(userSession.personId ?? -1) && [
    'dev-test.omega365.com',
    'dev-nt.omega365.com',
    'omega365-fallback.azurewebsites.net'
].includes(window.location.host);

const featureFlags = {
    experimentalMonaco: featureFlagEnabledForUser && true,
    enableTsIntellisense: featureFlagEnabledForUser || true,
    enableJsIntellisense: featureFlagEnabledForUser || true,
    enableVueIntellisense: featureFlagEnabledForUser || false,
    enableCdnIntelliSense: featureFlagEnabledForUser && false,
    enableVueCustomDeclaration: featureFlagEnabledForUser && true,
    enableJsxIntelliSense: featureFlagEnabledForUser || true
}

const { 
    m: monaco,
    l: languages,
    R: Range,
    e: editor,
    a: KeyMod,
    U: Uri,
    K: KeyCode,
    loadGrammars, 
    setupMonacoEnv, 
    loadOnigasm,
    getTheme
} = await import(featureFlags.experimentalMonaco ? 'monaco-volar-experimental-js' : 'monaco-volar-js');

import type DataObject from 'o365.modules.DataObject.ts';

declare global {
    var o365: {
        monaco?: any;
        monacoControl?: string;
    } | undefined;
}

window.o365 ??= { monaco };

export interface IMonacoControlOptions {
    appId: string;
    element: HTMLElement;
    dataObject?: DataObject;
    onSave: () => void;
    onDidChangeModelContent: (value: string) => void;
    libraryMode?: boolean;
    readOnly?: boolean;
}

export interface MonacoControlExtraLibState {
    editorModel: any;
    javaScriptExtraLibDisposable: any;
    typeScriptExtraLibDisposable: any;
    vueExtraLibDisposable: Promise<string | null> | null;
}

export { monaco }

export default class MonacoControl {
    private static onigasmLoaded = false;
    private static monacoEnvLoaded = false;

    private uid = crypto.randomUUID();

    private options: IMonacoControlOptions;

    private omega365VueWorkerRetrieveExtraLibBroadcastChannel = new BroadcastChannel('Omega365_Vue_Worker_Retrieve_Extra_Lib');
    private omega365MonacoUpdateExtraLibsBroadcastChannel = new BroadcastChannel('MONACO_UPDATE_EXTRA_LIBS');

    private models = new Map<string, any>();
    private modelStates = new Map();
    private extraLibsScripts = new Map<string, MonacoControlExtraLibState>();
    private appImportScripts: Promise<{ [key: string]: string; }> | null = null;
    private siteImportScripts: Promise<{ [key: string]: string; }> | null = null;
    private _resolveVueSetup = () => {};
    vueSetupPromise: Promise<void>;

    private currentModel: any = null;
    private editor: any = null;
    private _currentTheme: string = 'vs';

    /*private _completionItemProvider = new CompletionItemProvider();

    public get completionItemProvider(): CompletionItemProvider {
        return this._completionItemProvider;
    } */

    initPromise?: Promise<void>;

    public get currentTheme(): string {
        return this._currentTheme;
    }

    public set currentTheme(newValue: string) {
        if (this._currentTheme === newValue) {
            return;
        }

        this._currentTheme = newValue;

        editor.setTheme(this._currentTheme);
    }

    private get documentIsDarkTheme() {
        return document.documentElement.getAttribute("data-bs-theme") === "dark";
    }

    constructor(pOptions: IMonacoControlOptions){
        this.options = pOptions;

        window.o365!.monacoControl = this.uid
        this.vueSetupPromise = new Promise(res => this._resolveVueSetup = res);
    }

    public moveToPosition(pPos: any) {
        if (this.editor === null) {
            return;
        }

        this.editor.setScrollPosition({scrollTop: 0});
        this.editor.revealLine(pPos.startLineNumber);
        this.editor.revealLineInCenter(pPos.startLineNumber);
           
        let oldSelection = this.editor.getModel().selections;

        this.editor.setPosition({ column: pPos.startColumn, lineNumber: pPos.startLineNumber });
        this.editor.getModel().selections = oldSelection;
        this.editor.focus();
    }

    public replaceRange(pastePos: any, replacement: any) {
        const selection = this.editor.getSelection( );

        const range = new Range(
            pastePos.startLineNumber || selection.endLineNumber,
            pastePos.startColumn || selection.endColumn,
            pastePos.endLineNumber || selection.endLineNumber,
            pastePos.endColumn || selection.endColumn,
        );

        this.editor.executeEdits( '', [ { range, text: replacement } ] );

    }

    public setModelMarkers(pMarkers: any, owner: string = 'owner') {
        editor.setModelMarkers(this.editor.getModel(), owner, pMarkers);
    }

    public resetModels() {
        this.models.forEach((model, _key, _models) => {
            model?.dispose();
        });

        this.models.clear();
    }

    public async setCurrentModel(pKey: any, pValue: any, pLang: any, pName: any) {
        if (!pValue) pValue = "";

        if (this.currentModel) {
            this.modelStates.set(this.currentModel, this.editor.saveViewState());
        }

        const vLang = this.getLangFromExtension(pLang);
        
        if (!this.models.has(pKey)) {
            let vUri = undefined;
            if (pName) {
                try {
                    vUri = Uri.parse(`file:///${pName}`);
                } catch (err) {}
            }

            let model = null;

            if(vUri) model = editor.getModel(vUri);

            if (model === undefined || model === null) {
                model = editor.createModel(pValue, vLang, vUri);
            }

            this.lazyLoadExtraLibs(pName, undefined);

            this.models.set(pKey, model);
        }

        if(vLang === "vue"){
            (this.documentIsDarkTheme ? getTheme() : getTheme(false)).then((theme: string) => {
                this.currentTheme = theme;
            });
        } else {
            this.currentTheme = this.documentIsDarkTheme ? 'vs-dark' : 'vs';
        }

        if (this.currentModel === pKey && pValue !== this.editor.getModel().getValue()) {
            this.editor.getModel().setValue(pValue);
        }

        if (this.currentModel === pKey) return;

        if (featureFlags.enableJsxIntelliSense && vLang === 'javascript') {
            if (pLang === 'jsx') {
                languages.typescript.javascriptDefaults.setCompilerOptions({
                    ...languages.typescript.javascriptDefaults.getCompilerOptions(),
                    jsx: languages.typescript.JsxEmit.Preserve
                });
            } else {
                const javaScriptCompilerOptions = {
                    ...languages.typescript.javascriptDefaults.getCompilerOptions()
                };

                delete javaScriptCompilerOptions.jsx;

                languages.typescript.javascriptDefaults.setCompilerOptions(javaScriptCompilerOptions);
            }
        }

        this.editor.setModel(this.models.get(pKey));

        if (this.modelStates.has(pKey)) {
            this.editor.restoreViewState(this.modelStates.get(pKey));
        }
       
        this.currentModel = pKey;
    }

    public async lazyLoadExtraLibs(pName: string, pValue: string | undefined) {
        try {
            const uri = Uri.parse(`file:///${pName}`);

            let value = pValue;

            if (value === undefined) {
                const model = editor.getModel(uri);

                if (!model) {
                    return;
                }

                value = model.getValue();
            }

            if (this.options.appId) {
                this.appImportScripts ??= new Promise(async (resolve, reject) => {
                    try {
                        const appImportScripts = await Api.requestGet(`/nt/api/staticfiles/import-map/app/${this.options.appId}`);
                        let result = appImportScripts.imports;
                        if (this.options.libraryMode) {
                            Object.keys(result).forEach(key => {
                                result[`./${key}`] = result[key];
                                // const fileName = key.replace(`/nt/scripts/apps/${this.options.appId}/`,'')
                                // result[`./${fileName}`] = result[key];
                            });
                        }
                        resolve(result);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }

            const appImportScripts = this.appImportScripts ? await this.appImportScripts : null;
            
            this.addExtraLib(pName, uri, value!);

            const subDependencies = this.parseAndTraverseScript(pName, value!);

            const siteDependencies = new Array<{name: string, importScriptUrl: string, appId?: string}>();
            const appDependencies = new Array<{name: string, importScriptUrl: string, appId?: string}>();
            const cdnDependencies = new Array();

            for (let subDependency of subDependencies) {
                if (subDependency.startsWith('/nt/service-worker/dependencies/')) {
                    subDependency = subDependency.replace('/nt/service-worker/dependencies/', '');
                }

                const isLoadedAsExtraLib = this.extraLibsScripts.has(subDependency);

                if (isLoadedAsExtraLib) {
                    continue;
                }

                this.extraLibsScripts.set(subDependency, {
                    editorModel: null,
                    javaScriptExtraLibDisposable: null,
                    typeScriptExtraLibDisposable: null,
                    vueExtraLibDisposable: null,
                });

                let libUrl = getLibUrl(subDependency);

                if (libUrl === subDependency && appImportScripts && appImportScripts[subDependency]) {
                    libUrl = appImportScripts[subDependency];
                }

                if (
                    (
                        (
                            libUrl.startsWith('/scripts/site/') ||
                            libUrl.startsWith('/nt/scripts/site/') ||
                            libUrl.startsWith('/scripts/apps/') ||
                            libUrl.startsWith('/nt/scripts/apps/')
                        ) &&
                        subDependency.endsWith('.js') === false &&
                        subDependency.endsWith('.jsx') === false &&
                        subDependency.endsWith('.ts') === false &&
                        subDependency.endsWith('.tsx') === false
                    ) ||
                    (
                        libUrl.startsWith(cdnBaseUrl) &&
                        libUrl.endsWith('.js') === false &&
                        libUrl.endsWith('.jsx') === false &&
                        libUrl.endsWith('.ts') === false &&
                        libUrl.endsWith('.tsx') === false
                    )
                ) {
                    continue;
                }

                if (libUrl.startsWith('/scripts/site/') || libUrl.startsWith('/nt/scripts/site/')) {
                    siteDependencies.push({ name: subDependency, importScriptUrl: libUrl });
                } else if (libUrl.startsWith('/scripts/apps/') || libUrl.startsWith('/nt/scripts/apps/')) {
                    if (this.options.libraryMode) {
                        // appDependencies.push({ name: subDependency.replace('./', ''), importScriptUrl: libUrl, appId: this.options.appId });
                        appDependencies.push({ name: subDependency, importScriptUrl: libUrl, appId: this.options.appId });
                    } else {
                        appDependencies.push({ name: subDependency, importScriptUrl: libUrl, appId: this.options.appId });
                    }
                } else if (libUrl.startsWith(cdnBaseUrl)) {
                    cdnDependencies.push({
                        name: subDependency,
                        url: libUrl
                    });
                }
            }

            await Promise.allSettled([
                this.addSiteExtraLibs(siteDependencies),
                this.addAppExtraLibs(appDependencies),
                // this.addCdnExtraLibs(cdnDependencies)
            ]);
        } catch (reason) {
            console.error('Failed to lazyload dependencies for the following script: pName', reason);
        }
    }

    public parseAndTraverseScript = (pName: string, pValue: string) => {
        let babelParserConfig: any = undefined;

        if (pName.endsWith('.d.ts') && featureFlags.enableTsIntellisense) {
            babelParserConfig = {
                sourceType: 'module',
                plugins: [
                    ['typescript', { dts: true }]
                ],
            };
        } else if (pName.endsWith('.ts') && featureFlags.enableTsIntellisense) {
            babelParserConfig = {
                sourceType: 'module',
                plugins: ['typescript'],
            };
        } else if (pName.endsWith('.js') && featureFlags.enableJsIntellisense) {
            babelParserConfig = {
                sourceType: 'module'
            };
        } else {
            return [];
        }

        const ast = babelParser.parse(pValue, babelParserConfig);

        let subDependencies = new Array();

        babelTraverse(ast, {
            ImportDeclaration: this.resolveJsImportDeclaration.bind(this, subDependencies),
            ExportNamedDeclaration: this.resolveJsImportDeclaration.bind(this, subDependencies),
            ExportAllDeclaration: this.resolveJsImportDeclaration.bind(this, subDependencies),
            CallExpression: this.resolveJsCallExpression.bind(this, subDependencies)
        });

        return subDependencies;
    }

    public async addSiteExtraLibs(siteDependencies: Array<{name: string, importScriptUrl: string, appId?: string}>) {
        if (siteDependencies.length === 0) {
            return;
        }

        const scriptResponse = await this.getScriptsContent(siteDependencies);

        const promiseArray = new Array<Promise<void>>();

        for (const scriptRecord of scriptResponse) {
            if (scriptRecord.scriptContent === null) {
                continue;
            } 

            promiseArray.push(this.lazyLoadExtraLibs(scriptRecord.name, scriptRecord.scriptContent));
        }

        return await Promise.allSettled(promiseArray);
    }

    public async loadSiteExtraLib(name: string): Promise<{ id: string, value: string }> {
        const response = await Api.requestText(`/nt/api/staticfiles/declaration/${name}`);

        return {
            id: name,
            value: response
        };
    }

    public async addAppExtraLibs(appDependencies: Array<{name: string, importScriptUrl: string, appId?: string}>) {
        if (appDependencies.length === 0 || !this.options.appId) {
            return;
        }

        const scriptResponse = await this.getScriptsContent(appDependencies);

        const promiseArray = new Array<Promise<void>>();

        for (const scriptRecord of scriptResponse) {
            if (scriptRecord.scriptContent === null) {
                continue;
            } 

            promiseArray.push(this.lazyLoadExtraLibs(scriptRecord.name, scriptRecord.scriptContent));
        }

        return await Promise.allSettled(promiseArray);
    }

    public async addCdnExtraLibs(cdnDependencies: Array<{ name: string, url: string }>) {
        if (cdnDependencies.length === 0) {
            return;
        }

        for (const cdnDependency of cdnDependencies) {
            const hasExtraLib = this.extraLibsScripts.has(cdnDependency.url);

            if (hasExtraLib) {
                continue;
            }

            if (cdnDependency.url !== cdnDependency.name) {
                var javaScriptCompilerOptions = languages.typescript.javascriptDefaults.getCompilerOptions();

                javaScriptCompilerOptions.paths[cdnDependency.name] = [cdnDependency.url];

                languages.typescript.javascriptDefaults.setCompilerOptions(javaScriptCompilerOptions);

                var typeScriptCompilerOptions = languages.typescript.typescriptDefaults.getCompilerOptions();

                typeScriptCompilerOptions.paths[cdnDependency.name] = [cdnDependency.url];

                languages.typescript.typescriptDefaults.setCompilerOptions(typeScriptCompilerOptions);
            }

            const monacoUrl = Uri.parse(cdnDependency.url);

            const cdnDependencyResponse = await fetch(cdnDependency.url);
            const cdnDependencyText = await cdnDependencyResponse.text();

            this.addExtraLib(cdnDependency.name, monacoUrl, cdnDependencyText);
        }
    }

    public addExtraLib(pName: string, pUri: any, pValue: string) {
        const hasModel = !!editor.getModel(pUri);
        const isJavaScript = pName.endsWith('.js') || pName.endsWith('.jsx') || pUri.toString().endsWith('.js') || pUri.toString().endsWith('.jsx');
        const isTypeScript = pName.endsWith('.ts') || pName.endsWith('.tsx') || pUri.toString().endsWith('.ts') || pUri.toString().endsWith('.tsx');

        if (!this.extraLibsScripts.has(pName)) {
            this.extraLibsScripts.set(pName, {
                editorModel: null,
                javaScriptExtraLibDisposable: null,
                typeScriptExtraLibDisposable: null,
                vueExtraLibDisposable: null,
            });
        }

        const extraLibState = this.extraLibsScripts.get(pName)!;

        if (isTypeScript || (hasModel === false && isJavaScript)) {
            extraLibState.javaScriptExtraLibDisposable = languages.typescript.javascriptDefaults.addExtraLib(pValue, pUri);
        }

        if (isJavaScript || (hasModel === false && isTypeScript)) {
            extraLibState.typeScriptExtraLibDisposable = languages.typescript.typescriptDefaults.addExtraLib(pValue, pUri);
        }

        if (hasModel && isTypeScript) {
            extraLibState.typeScriptExtraLibDisposable?.dispose();
        }

        if (hasModel && isJavaScript) {
            extraLibState.javaScriptExtraLibDisposable?.dispose();
        }

        if (pName === 'VirtualDataObjectItemModels.d.ts') {
            extraLibState.vueExtraLibDisposable = Promise.resolve(pValue);
        }
    }

    public resolveJsImportDeclaration(subDependencies: Array<string>, path: any) {
        const sourceValue = path?.node?.source?.value;

        if ([undefined, null, ''].includes(sourceValue?.trim())) {
            return;
        }

        subDependencies.push(sourceValue);
    }

    public resolveJsCallExpression(subDependencies: Array<string>, path: any) {
        const node = path.node;
        const nodeArguments = node.arguments;
        const callee = node.callee;

        if ([undefined, null].includes(nodeArguments) || [undefined, null].includes(callee)) {
            return;
        }

        const calleeType = callee.type;

        const isImport = calleeType === 'Import' && nodeArguments.length === 1 && nodeArguments[0].type === 'StringLiteral';
        const isO365ImportFunction = ['getLibUrl', 'loadStyle', 'loadCdnStyle', 'getTemplate'].includes(callee.name) && nodeArguments.length === 1 && nodeArguments[0].type === 'StringLiteral';

        if (isImport || isO365ImportFunction) {
            subDependencies.push(nodeArguments[0].value);
        }
    }

    public getLangFromExtension(extension: string): string {
        switch(extension){
            case "vb":
                return "vb";
            case "cs":
            case "csx":
                return "csharp";
            case "fs":
            case "fsx":
                return "fsharp";
            case "css":
                return "css";
            case "less":
                return "less";
            case "js":
            case "jsx":
                return "javascript";
            case "ts":
            case "bundle":
                return "typescript";
            case "rs":
                return "rust";
            case "razor":
                return "razor";
            case "config":
            case "settings":
            case "resx":
            case "myapp":
            case "xml":
            case "lic":
            case "vbproj":
            case "csproj":
            case "fsproj":
            case "targets":
            case "wixproj":
            case "wxs":
            case "proj":
            case "props":
            case "xslt":
                return "xml";
            case "manifest":
            case "json":
                return "json";
            case "ini":
            case "sln":
                return "ini";
            case "sql":
                return "sql";
            case "markdown":
                return "markdown";
            case "vue":
                return "vue";
            case "tpl":
                return 'html';
            default:
                return extension;
        }
    }

    public openFind(pText: string): void {
        const model = this.editor.getModel();

        if (model.findMatches(pText)[0]) {
            const range = model.findMatches(pText)[0].range;

            this.editor.setSelection(range);
            this.editor.revealLineInCenter(range.startLineNumber);
        }

        this.editor.getAction('actions.find').run();
    }

    private async doLoadOnigasm(): Promise<void> {
        if(!MonacoControl.onigasmLoaded){
            MonacoControl.onigasmLoaded = true;
            await loadOnigasm();
        }
    }

    private async doInitMonacoEnv(): Promise<void> {
        if(!MonacoControl.monacoEnvLoaded){
            MonacoControl.monacoEnvLoaded = true;
            await setupMonacoEnv();
        }
    }

    public dispose(): void {
        this.editor.dispose();
    }

    public async initMonaco() {
        let initPromiseResolve = () => {}
        this.initPromise = new Promise((res) => {
            initPromiseResolve = res;
        });

        this.initializeVue();

        this.initializeJavaScript();

        this.initializeTypeScript();
        
        this.initializeJson();

        await Promise.all([this.doInitMonacoEnv(), this.doLoadOnigasm()]);

        const monacoEditor = editor.create(this.options.element, {
            readOnly: this.options.dataObject ? !this.options.dataObject.allowUpdate : this.options.readOnly ?? false,
            // fontFamily: "Cascadia Code, Consolas",
            automaticLayout: true
        });

        this.editor = monacoEditor;

        loadGrammars(monacoEditor);
        
        if (this.options?.element?.id) {
            (window as { [key: string]: any})[this.options.element.id] = monacoEditor;
        }

        if (document.documentElement.getAttribute("data-bs-theme") == "dark") {
            this.currentTheme = 'vs-dark';
        }
    
        this.editor.addCommand(KeyMod.CtrlCmd | KeyCode.KeyS, () => {
            if(this.options.onSave){
                this.options.onSave();  
            }
        });

        if (this.options.onDidChangeModelContent) {
            this.editor.onDidChangeModelContent(() => {
                const value: string = this.editor.getModel().getValue();

                clearTimeout(this._checkForCustomWarningsHandle);
                this._checkForCustomWarningsHandle = setTimeout(() => this._checkForCustomWarnings(this.editor.getModel()), 500);

                this.options.onDidChangeModelContent(value);
            });
        }

        
        monaco.languages.registerCodeActionProvider("vue", {provideCodeActions: (model,range,context,token) => {
            const actions = [];
            for (const error of context.markers) {
                if (error.code.startsWith("$t(`") && error.message.startsWith("$t(`")) {
                    let newString = error.code.substr(4, error.code.length-6);
                    const templateArgumentExpressions : string[] = [...error.code.matchAll(/\${([^}]+)}/g).map(a => a[1])];
                    let outputArgumentExpressions : string[] = [];
                    let indexNextExpression = 1;

                    for (let arg of templateArgumentExpressions) {
                        let argName = arg;
                        if (!/^\w+$/.test(arg)) {
                            argName = `exp${indexNextExpression++}`;
                        }

                        newString = newString.replaceAll(`\${${arg}}`, `{${argName}}`);
                        outputArgumentExpressions.push(`${argName}: ${arg}`);
                    }

                    newString = newString.replace('"', '\\"');

                    let outputArgumentExpressionsString = "";
                    if (outputArgumentExpressions.length) {
                        outputArgumentExpressionsString = `, {${outputArgumentExpressions.join(',')}}`;
                    }
                     
                    actions.push(
                        this._getErrorAction(model, error, 
                            "Replace `...` with \"...\"", 
                            `$t("${newString}"${outputArgumentExpressionsString})`,
                            true
                        )
                    );
                }
            }
            
            return {
                    actions: actions,
                    dispose: () => {}
                }
        }});

        monaco.languages.registerCompletionItemProvider("vue", 
        {
            provideCompletionItems: (textModel : any, position : {lineNumber: number, column: number}) => { 
                const result = {
                    suggestions : new Array<any>()
                };
            
                // Search if $t is at current position, necessary because $ is not considered a part of word by monaco
                const match = textModel.findPreviousMatch('$t', position, false, true, null, false);
                if (match?.range?.endLineNumber == position?.lineNumber &&
                    match?.range?.endColumn == position?.column) {

                    result.suggestions.push({
                            label: '$t("Hello {name}", {name: \'Omega\'})',
                            kind: monaco.languages.CompletionItemKind.Function,
                            documentation: "Translate",
                            insertText: 't($0)',
                            insertTextRules: 4
                        });
                }
                
                return result;
            }, 
            resolveCompletionItem: (item : any) => item
        });

        initPromiseResolve();
    }

    private initializeJavaScript() {
        languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            ...languages.typescript.javascriptDefaults.getDiagnosticsOptions(),
            diagnosticCodesToIgnore: [ 2691, 2792 ]
        });

        var javaScriptCompilerOptions = {
            ...languages.typescript.javascriptDefaults.getCompilerOptions(),
            allowNonTsExtensions: true,
            allowJs: true,
            checkJs: true,
            strictNullChecks: false,
            paths: {
                '*\.ts': [ 'file:///*' ],
                '/nt/service-worker/dependencies/*\.ts': [ 'file:///*' ]
            },
            moduleResolution: languages.typescript.ModuleResolutionKind.Classic,
            module: languages.typescript.ModuleKind.ESNext,
            target: languages.typescript.ScriptTarget.ESNext,
            declaration: true,
            strict: true,
            noImplicitAny: true,
            strictFunctionTypes: true,
            strictPropertyInitialization: true,
            noFallthroughCasesInSwitch: true,
            noImplicitReturns: true,
            noUnusedParameters: true,
            noUnusedLocals: true,
        };

        languages.typescript.javascriptDefaults.setCompilerOptions(javaScriptCompilerOptions);
    }

    private initializeTypeScript() {
        languages.typescript.typescriptDefaults.setDiagnosticsOptions({
            ...languages.typescript.typescriptDefaults.getDiagnosticsOptions(),
            diagnosticCodesToIgnore: [ 2691, 2792 ]
        });

        var typescriptCompilerOptions = {
            ...languages.typescript.typescriptDefaults.getCompilerOptions(),
            allowNonTsExtensions: true,
            allowImportingTsExtensions: true,
            allowJs: true,
            checkJs: true,
            strictNullChecks: true,
            paths: {
                '*\.ts': [ 'file:///*' ],
                '/nt/service-worker/dependencies/*\.ts': [ 'file:///*' ]
            },
            moduleResolution: languages.typescript.ModuleResolutionKind.Classic,
            module: languages.typescript.ModuleKind.ESNext,
            target: languages.typescript.ScriptTarget.ESNext,
            declaration: true,
            strict: true,
            noImplicitAny: true,
            strictFunctionTypes: true,
            strictPropertyInitialization: true,
            noFallthroughCasesInSwitch: true,
            noImplicitReturns: true,
            noUnusedParameters: true,
            noUnusedLocals: true,
        };

        languages.typescript.typescriptDefaults.setCompilerOptions(typescriptCompilerOptions);
    }

    private initializeVue() {
        monaco.languages.vue ??= {};

        monaco.languages.vue.onSetup = this.onVueSetup.bind(this);

        this.omega365MonacoUpdateExtraLibsBroadcastChannel.onmessage = this.omega365MonacoUpdateExtraLibsBroadcastChannelOnMessage.bind(this);

        this.omega365VueWorkerRetrieveExtraLibBroadcastChannel.onmessage = this.omega365VueWorkerRetrieveExtraLibBroadcastChannelOnMessage.bind(this);
    }

    private initializeJson() {
        languages.json.jsonDefaults.diagnosticsOptions.enableSchemaRequest = true;
    }

    private async onVueSetup() {
        const proxy = await monaco.languages.vue?.worker?.getProxy();

        if (!proxy) {
            return;
        }

        this._resolveVueSetup();

        proxy.updateOmega365MonacoControlUid(this.uid);

        if (featureFlags.enableVueIntellisense) {
            for (const [key, extraLibState] of this.extraLibsScripts.entries()) {
                let extension = key.split('.').pop();

                if (key.endsWith('.d.ts')) {
                    extension = 'd.ts';
                }

                if (!['ts', 'js', 'vue', 'jsx', 'tsx', 'd.ts'].includes(extension ?? '')) {
                    continue;
                }

                if (!extraLibState.vueExtraLibDisposable) {
                    continue;
                }

                const value = await extraLibState.vueExtraLibDisposable;

                if (value === null) {
                    continue;
                }

                proxy.updateOmega365ExtraLib(
                    `file:///node_modules/${extension === 'ts' ? `${key}.ts` : key}`,
                    value
                );
            }
        }

        this.vueInitializeLanguageConfiguration();

        this.vueInitializeCommentsOverride();

        this.vueInitializeCustomVueDeclarations(proxy);
    }

    private async omega365MonacoUpdateExtraLibsBroadcastChannelOnMessage(event: MessageEvent) {
        if (event?.data?.Type !== 'save') {
            return;
        }

        const id = event?.data?.id;

        if (!this.extraLibsScripts.has(id)) {
            return;
        }

        const extraLibState = this.extraLibsScripts.get(id)!;
        const value = event.data.value;
        
        if (extraLibState.vueExtraLibDisposable) {
            if (featureFlags.enableVueIntellisense) {
                extraLibState.vueExtraLibDisposable = Promise.resolve(value);

                const proxy = await monaco.languages.vue.worker?.getProxy();

                if (proxy) {
                    let extension = id.split('.').pop();

                    if (id.endsWith('.d.ts')) {
                        extension = 'd.ts';
                    }

                    proxy.updateOmega365ExtraLib(
                        `file:///node_modules/${extension === 'ts' ? `${id}.ts` : id}`,
                        value
                    );
                }
            }
        }

        this.lazyLoadExtraLibs(id, value);
    }

    private async omega365VueWorkerRetrieveExtraLibBroadcastChannelOnMessage(event: MessageEvent) {
        if (!featureFlags.enableVueIntellisense) {
            return;
        }

        try {
            const data = event.data;
            const jsonData = JSON.parse(data);
            
            const monacoControlUid = jsonData.monacoControlUid;

            if (monacoControlUid !== this.uid) {
                return;
            }
            
            const proxy = await monaco.languages.vue.worker?.getProxy();

            if (!proxy) {
                return;
            }

            const fileName = jsonData.fileName.replace('/node_modules/', '').replace('@types', '');

            if (fileName.includes('/index') || fileName.includes('/package')) {
                return;
            }

            let extension = fileName.split('.').pop();

            if (fileName.endsWith('.d.ts')) {
                extension = 'd.ts';
            }

            if (!['ts', 'js', 'vue', 'jsx', 'tsx'].includes(extension)) {
                return;
            }

            if (this.extraLibsScripts.has(fileName) && this.extraLibsScripts.get(fileName)!.vueExtraLibDisposable) {
                return;
            }

            this.siteImportScripts ??= new Promise(async (resolve, reject) => {
                try {
                    const siteImportScripts = await Api.requestGet(`/nt/api/staticfiles/import-map/site`);

                    resolve(siteImportScripts.imports);
                } catch (reason) {
                    reject(reason);
                }
            });

            const siteImportScripts = await this.siteImportScripts;

            if (this.options.appId) {
                this.appImportScripts ??= new Promise(async (resolve, reject) => {
                    try {
                        const appImportScripts = await Api.requestGet(`/nt/api/staticfiles/import-map/app/${this.options.appId}`);

                        resolve(appImportScripts.imports);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }

            const appImportScripts = this.appImportScripts ? await this.appImportScripts : null;

            if (!siteImportScripts[fileName] && !(appImportScripts?.[fileName] ?? false)) {
                return;
            }

            let hasExtraLibLoaded = false;

            for (const [key, extraLibState] of this.extraLibsScripts.entries()) {
                if (fileName.includes(key) && (extraLibState.vueExtraLibDisposable)) {
                    hasExtraLibLoaded = true;

                    const value = await extraLibState.vueExtraLibDisposable;

                    if (value) {
                        proxy.updateOmega365ExtraLib(
                            `file:///node_modules/${extension === 'ts' ? `${fileName}.ts` : fileName}`,
                            value
                        );
                    }
                    break;
                }
            }

            if (hasExtraLibLoaded) {
                return;
            }

            const promise = new Promise<string | null>(async (resolve, reject) => {
                try {
                    const script = {
                        name: fileName,
                        importScriptUrl: appImportScripts?.[fileName] ?? siteImportScripts[fileName],
                        appId: siteImportScripts[fileName] ? undefined : this.options.appId
                    };

                    const scriptResult = await this.getScriptsContent([script]);

                    return resolve(scriptResult[0].scriptContent ?? null);
                } catch (reason) {
                    reject(reason);
                }
            });

            if (!this.extraLibsScripts.has(fileName)) {
                this.extraLibsScripts.set(fileName, {
                    editorModel: null,
                    javaScriptExtraLibDisposable: null,
                    typeScriptExtraLibDisposable: null,
                    vueExtraLibDisposable: null,
                });
            }
            /* if (!this.extraLibsScripts.has(fileName.substring(0, fileName.lastIndexOf('.vue')) + '.d.ts')) {
                this.extraLibsScripts.set(fileName.substring(0, fileName.lastIndexOf('.vue')) + '.d.ts', {
                    editorModel: null,
                    javaScriptExtraLibDisposable: null,
                    typeScriptExtraLibDisposable: null,
                    vueExtraLibDisposable: null,
                })
            } */

            this.extraLibsScripts.get(fileName)!.vueExtraLibDisposable = promise;
            /* this.extraLibsScripts.get(fileName.substring(0, fileName.lastIndexOf('.vue')) + '.d.ts')!.vueExtraLibDisposable = promise; */

            const extraLibContent = await promise;

            if (extraLibContent === null) {
                return;
            }

            proxy.updateOmega365ExtraLib(
                `file:///node_modules/${extension === 'ts' ? `${fileName}.ts` : fileName}`,
                extraLibContent
            );
            /* proxy.updateOmega365ExtraLib(
                `file:///node_modules/${fileName.substring(0, fileName.lastIndexOf('.vue')) + '.d.ts'}`,
                extraLibContent
            ); */
        } catch (reason) {
            console.error(reason);
        }
    }

    private vueInitializeCommentsOverride() {
        const _onWillExecuteCommand = this.editor._commandService._onWillExecuteCommand;
        const onCommand = this.editor._commandService._onWillExecuteCommand.fire.bind(_onWillExecuteCommand);
        
        this.editor._commandService._onWillExecuteCommand.fire = (event: {commandId: string}) => {
            const languageId = this.editor.getModel().getLanguageId();

            if (languageId !== 'vue') {
                return onCommand.call(event);
            }

            switch (event.commandId) {
                case 'editor.action.addCommentLine':
                    return this.vueAddLineComments();
                case 'editor.action.removeCommentLine':
                    return this.vueRomoveLineComments();
                case 'editor.action.commentLine':
                    return this.vueToggleLineComments();
                case 'editor.action.blockComment':
                    return this.vueToggleBlockComments();
            }

            return onCommand.call(event);
        }
    }

    private vueInitializeCustomVueDeclarations(proxy: any) {
        if (!featureFlags.enableVueCustomDeclaration) {
            return;
        }

        proxy.updateOmega365CustomVueDeclarations(`
            declare module '@vue/runtime-dom' {
                interface ComponentCustomProperties {
                    \$format: (pValue: any, pColumn: any) => any,
                    \$formatDate: (pDate: any, pFormat: any, utc: any) => any,
                    \$formatNumber: (pNumber: any, pFormat: any) => any,
                    \$formatFileSize: (pNumber: any) => any,
                    /** Function for translating strings */
                    \$t: (pValue: string) => string,
                    \$track: (pName: string, pProperties?: object) => void,
                }
            }

            declare global {
                interface Window {
                    o365_i18n?: {
                        [key: string]: string
                    },
                    o365_framework_i18n?: {
                        [key: string]: string
                    },
                    \$t: (pValue: string) => string;
                }
            }
        `)
    }

    private vueInitializeLanguageConfiguration() {
        languages.setLanguageConfiguration('vue', {
            "brackets": [
                [
                    "<!--",
                    "-->"
                ],
                // [
                // 	"<",
                // 	">"
                // ],
                [
                    "{",
                    "}"
                ],
                [
                    "(",
                    ")"
                ]
            ],
            "autoClosingPairs": [
                // html
                {
                    "open": "{",
                    "close": "}"
                },
                {
                    "open": "[",
                    "close": "]"
                },
                {
                    "open": "(",
                    "close": ")"
                },
                {
                    "open": "'",
                    "close": "'"
                },
                {
                    "open": "\"",
                    "close": "\""
                },
                {
                    "open": "<!--",
                    "close": "-->",
                    "notIn": [
                        "comment",
                        "string"
                    ]
                },
                // javascript
                {
                    "open": "`",
                    "close": "`",
                    "notIn": [
                        "string",
                        "comment"
                    ]
                },
                {
                    "open": "/**",
                    "close": " */",
                    "notIn": [
                        "string"
                    ]
                }
            ],
            "autoCloseBefore": ";:.,=}])><`'\" \n\t",
            "surroundingPairs": [
                // html
                {
                    "open": "'",
                    "close": "'"
                },
                {
                    "open": "\"",
                    "close": "\""
                },
                {
                    "open": "{",
                    "close": "}"
                },
                {
                    "open": "[",
                    "close": "]"
                },
                {
                    "open": "(",
                    "close": ")"
                },
                // {
                // 	"open": "<",
                // 	"close": ">"
                // },
                // javascript
                [
                    "`",
                    "`"
                ],
            ],
            "colorizedBracketPairs": [],
            "folding": {
                "markers": {
                    "start": new RegExp("^\\s*<!--\\s*#region\\b.*-->"),
                    "end": new RegExp("^\\s*<!--\\s*#endregion\\b.*-->")
                }
            },
            "wordPattern": "(-?\\d*\\.\\d\\w*)|([^\\`\\~\\!\\@\\$\\^\\&\\*\\(\\)\\=\\+\\[\\{\\]\\}\\\\\\|\\;\\:\\'\\\"\\,\\.\\<\\>\\/\\s]+)",
            "onEnterRules": [
                {
                    "beforeText": new RegExp("<(?!\\?|(?:area|base|br|col|frame|hr|html|img|input|keygen|link|menuitem|meta|param|source|track|wbr|script|style|=>)\\b|[^>]*\\/>)[-_\\.A-Za-z0-9]+(?=\\s|>)\\b[^>]*>(?!.*<\\/\\1>)|<!--(?!.*-->)", "i"),
                    "afterText": new RegExp("^<\\/([_:\\w][_:\\w-.\\d]*)\\s*>", "i"),
                    "action": {
                        "indent": "indentOutdent"
                    }
                },
                {
                    "beforeText": new RegExp("<(?!\\?|(?:area|base|br|col|frame|hr|html|img|input|keygen|link|menuitem|meta|param|source|track|wbr|script|style|=>)\\b|[^>]*\\/>)[-_\\.A-Za-z0-9]+(?=\\s|>)\\b[^>]*>(?!.*<\\/\\1>)|<!--(?!.*-->)", "i"),
                    "action": {
                        "indent": "indent"
                    }
                }
            ],
            "indentationRules": {
                "increaseIndentPattern": new RegExp("<(?!\\?|(?:area|base|br|col|frame|hr|html|img|input|keygen|link|menuitem|meta|param|source|track|wbr|script|style|=>)\\b|[^>]*\\/>)[-_\\.A-Za-z0-9]+(?=\\s|>)\\b[^>]*>(?!.*<\\/\\1>)|<!--(?!.*-->)"),
                "decreaseIndentPattern": new RegExp("^\\s*(<\\/(?!html)[-_\\.A-Za-z0-9]+\\b[^>]*>|-->|\\})")
            }
        });
    }

    private async vueAddLineComments() {
        const selections = this.editor.getSelections();

        for (const selection of selections) {
            const startLineNumber = selection.startLineNumber;
            const endLineNumber = selection.endLineNumber;

            for (let lineNumber = startLineNumber; lineNumber <= endLineNumber; lineNumber++) {
                this.vueAddLineComment(lineNumber);
            }
        }
    }

    private async vueAddLineComment(lineNumber: any) {
        const model = this.editor.getModel();

        if (!model) {
            return;
        }

        const lineContent: string = model.getLineContent(lineNumber);

        if (lineContent.trim().length === 0) {
            return;
        }

        const languageId = this.vueGetLanguageForCurrentLine(model, lineNumber);

        let commentStartSyntax = '',
            commentEndSyntax = '';

        switch (languageId) {
            case 'html':
                commentStartSyntax = '<!-- ';
                commentEndSyntax = ' -->';
                break;
            case 'javascript':
            case 'typescript':
                commentStartSyntax = '// ';
                break;
            case 'css':
            case 'less':
            case 'scss':
                commentStartSyntax = '/* ';
                commentEndSyntax = ' */';
                break;
        }

        const textStart = lineContent.search(/\S|$/); // Find index of first non-whitespace character
        const textEnd = lineContent.search(/\s*$/) || lineContent.length; // Find index of last non-whitespace character

        const newText = lineContent.substring(0, textStart) + commentStartSyntax +
                        lineContent.substring(textStart, textEnd) + commentEndSyntax +
                        lineContent.substring(textEnd);

        const range = new monaco.Range(lineNumber, 1, lineNumber, lineContent.length + 1);
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range: range, text: newText, forceMoveMarkers: true };
        
        this.editor.executeEdits('', [op]);
    }

    private async vueRomoveLineComments() {
        const selections = this.editor.getSelections();

        for (const selection of selections) {
            const startLineNumber = selection.startLineNumber;
            const endLineNumber = selection.endLineNumber;

            for (let lineNumber = startLineNumber; lineNumber <= endLineNumber; lineNumber++) {
                this.vueRemoveLineComment(lineNumber);
            }
        }
    }

    private async vueRemoveLineComment(lineNumber: any) {
        const model = this.editor.getModel();

        if (!model) {
            return;
        }

        const lineContent: string = model.getLineContent(lineNumber);

        if (lineContent.trim().length === 0) {
            return;
        }

        const languageId = this.vueGetLanguageForCurrentLine(model, lineNumber);

        let commentStartSyntax,
            commentEndSyntax;

        switch (languageId) {
            case 'html':
                commentStartSyntax = '<!-- ';
                commentEndSyntax = ' -->';
                break;
            case 'javascript':
            case 'typescript':
                commentStartSyntax = '// ';
                commentEndSyntax = '';
                break;
            case 'css':
            case 'less':
            case 'scss':
                commentStartSyntax = '/* ';
                commentEndSyntax = ' */';
                break;
            default:
                return;
        }

        const lineStartsWithCommentSyntax = lineContent.trimStart().startsWith(commentStartSyntax);
        const lineEndssWithCommentSyntax = commentEndSyntax === '' || lineContent.trimEnd().endsWith(commentEndSyntax);

        if (!lineStartsWithCommentSyntax || !lineEndssWithCommentSyntax) {
            return;
        }

        let newText = lineContent.replace(commentStartSyntax, '');

        if (commentEndSyntax) {
            newText = newText.replace(commentEndSyntax, '');
        }

        const range = new monaco.Range(lineNumber, 1, lineNumber, lineContent.length + 1);
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range: range, text: newText, forceMoveMarkers: true };

        this.editor.executeEdits('', [op]);
    }

    private async vueToggleLineComments() {
        const selections = this.editor.getSelections();

        for (const selection of selections) {
            const startLineNumber = selection.startLineNumber;
            const endLineNumber = selection.endLineNumber;

            for (let lineNumber = startLineNumber; lineNumber <= endLineNumber; lineNumber++) {
                this.vueToggleLineComment(lineNumber);
            }
        }
    }

    private async vueToggleLineComment(lineNumber: any) {
        const model = this.editor.getModel();
        
        if (!model) {
            return;
        }

        const lineContent = model.getLineContent(lineNumber);

        if (lineContent.trim().length === 0) {
            return;
        }

        const languageId = this.vueGetLanguageForCurrentLine(model, lineNumber);

        let commentStartSyntax,
            commentEndSyntax;

        switch (languageId) {
            case 'html':
                commentStartSyntax = '<!-- ';
                commentEndSyntax = ' -->';
                break;
            case 'javascript':
            case 'typescript':
                commentStartSyntax = '// ';
                commentEndSyntax = '';
                break;
            case 'css':
            case 'less':
            case 'scss':
                commentStartSyntax = '/* ';
                commentEndSyntax = ' */';
                break;
            default:
                return;
        }

        const lineStartsWithCommentSyntax = lineContent.trimStart().startsWith(commentStartSyntax);
        const lineEndssWithCommentSyntax = commentEndSyntax === '' || lineContent.trimEnd().endsWith(commentEndSyntax);

        let newText;

        if (lineStartsWithCommentSyntax && lineEndssWithCommentSyntax) {
            newText = lineContent.replace(commentStartSyntax, '');

            if (commentEndSyntax) {
                newText = newText.replace(commentEndSyntax, '');
            }
        } else {
            const textStart = lineContent.search(/\S|$/); // Find index of first non-whitespace character
            const textEnd = lineContent.search(/\s*$/) || lineContent.length; // Find index of last non-whitespace character

            newText = lineContent.substring(0, textStart) + commentStartSyntax +
                            lineContent.substring(textStart, textEnd) + commentEndSyntax +
                            lineContent.substring(textEnd);
        }

        const range = new monaco.Range(lineNumber, 1, lineNumber, lineContent.length + 1);
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range: range, text: newText, forceMoveMarkers: true };

        this.editor.executeEdits('', [op]);
    }

    private async vueToggleBlockComments() {
        const selections = this.editor.getSelections();

        for (const selection of selections) {
            this.vueToggleBlockComment(selection);
        }
    }

    private async vueToggleBlockComment(selection: any) {
        const model = this.editor.getModel();
        
        if (!model) {
            return;
        }

        const languageId = this.vueGetLanguageForCurrentLine(model, selection.startLineNumber);

        let blockCommentStart,
            blockCommentEnd;

        switch (languageId) {
            case 'html':
                blockCommentStart = '<!--';
                blockCommentEnd = '-->';
                break;
            case 'javascript':
            case 'typescript':
                blockCommentStart = '/*';
                blockCommentEnd = '*/';
                break;
            case 'css':
            case 'less':
            case 'scss':
                blockCommentStart = '/*';
                blockCommentEnd = '*/';
                break;
            default:
                return;
        }

        let startLineNumber = selection.startLineNumber;
        let startColumn = selection.startColumn;
        let endLineNumber = selection.endLineNumber;
        let endColumn = selection.endColumn;

        const textInRange = model.getValueInRange(selection);
        const isCommented = textInRange.startsWith(blockCommentStart) && textInRange.endsWith(blockCommentEnd);

        if (isCommented) {
            const range = new monaco.Range(startLineNumber, startColumn, endLineNumber, endColumn);
            const newText = textInRange.slice(blockCommentStart.length, -blockCommentEnd.length);

            this.editor.executeEdits('', [{ range, text: newText }]);
        } else {
            const range = new monaco.Range(startLineNumber, startColumn, endLineNumber, endColumn);
            const newText = blockCommentStart + textInRange + blockCommentEnd;

            this.editor.executeEdits('', [{ range, text: newText }]);
        }
    }

    private vueGetLanguageForCurrentLine(model: any, lineNumber: number) {
        let currentLanguage = null;

        for (let i = lineNumber; i > 0; i--) {
            const line = model.getLineContent(i).trim();

            if (line.startsWith('<template')) {
                currentLanguage = 'html';
                break;
            } else if (line.startsWith('<script')) {
                currentLanguage = 'javascript';
                break;
            } else if (line.startsWith('<style')) {
                currentLanguage = 'css';
                break;
            }
        }

        return currentLanguage;
    }

    private async getScriptsContent(scripts: Array<{name: string, importScriptUrl: string, appId?: string}>): Promise<Array<{name: string, scriptContent: string | null}>> {
        return await this.getScriptsContentFromLocalCache(scripts);
    }

    private async getScriptsContentFromLocalCache(scripts: Array<{name: string, importScriptUrl: string, appId?: string}>): Promise<Array<{name: string, importScriptUrl: string, appId?: string, scriptContent: string | null}>> {
        const scriptsWithResult = new Array<{name: string, importScriptUrl: string, appId?: string, scriptContent: string | null}>();

        for (const script of scripts) {
            const localStorageValue = localStorageHelper.getItem(`monaco-editor-intellisense-${script.appId ?? 'site'}-${script.name}`, { global: true }, '');

            if (localStorageValue !== script.importScriptUrl) {
                scriptsWithResult.push({
                    ...script,
                    scriptContent: null
                });

                continue;
            }

            const cache = await caches.open('monaco-editor-intellisense-scripts');

            const response = await cache.match(`/api/dev/monaco-editor/intellisense/${script.appId ?? 'site'}/${script.name}`);

            if (response === undefined || response.status !== 200) {
                scriptsWithResult.push({
                    ...script,
                    scriptContent: null
                });

                continue;
            }

            const scriptContent = await response.text();

            scriptsWithResult.push({
                ...script,
                scriptContent: scriptContent
            });
        }

        const scriptsWithNoResult = scriptsWithResult.filter((script) => {
            return script.scriptContent === null;
        });

        const scriptsWithNoResultWithValueFromDB = await this.getScriptsContentFromDatabase(scriptsWithNoResult);

        /* if ([113504].includes(userSession.personId ?? -1)) {
            return [
                ...scriptsWithResult.filter((script) => script.scriptContent !== null),
                ...scriptsWithNoResultWithValueFromDB
            ];
        } else { */
            return [
                ...scriptsWithResult.filter((script) => script !== null),
                ...scriptsWithNoResultWithValueFromDB
            ];
        //}
        
    }

    private async getScriptsContentFromDatabase(scripts: Array<{ name: string, importScriptUrl: string, appId?: string }>): Promise<Array<{name: string, importScriptUrl: string, appId?: string, scriptContent: string | null}>> {
        const siteScripts = scripts.filter((script) => script.appId === undefined);
        const appScripts = scripts.filter((script) => script.appId !== undefined);

        const sendRequest = async (
            scripts: Array<{ name: string, importScriptUrl: string, appId?: string }>,
            viewName: string,
            whereClause: string
        ): Promise<Array<{ name: string, importScriptUrl: string, appId?: string, scriptContent: string | null }>> => {
            const response = await fetch('/nt/api/data/monaco-editor-intellisense', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    viewName,
                    distinctRows: false,
                    fields: [{name: 'ID'}, {name: 'ContentTest'}],
                    maxRecords: -1,
                    operation: 'retrieve',
                    skip: 0,
                    whereClause
                })
            });

            if (!response || response.status !== 200) {
                return [];
            }

            const scriptResponseJson = await response.json();

            for (const script of scripts as Array<{ name: string, importScriptUrl: string, appId?: string, scriptContent: string | null }>) {
                script.scriptContent = (scriptResponseJson.success as Array<{ ID: string, ContentTest: string }>).find((dbScript) => dbScript.ID === script.name)?.ContentTest ?? null;
            }

            return scripts as Array<{ name: string, importScriptUrl: string, appId?: string, scriptContent: string | null }>;
        }

        const sendDeclarationRequest = async (
            script: { name: string, importScriptUrl: string, appId?: string},
        ): Promise<{ name: string, importScriptUrl: string, appId?: string, scriptContent: string | null }> => {
            let declarations: { name: string, importScriptUrl: string, appId?: string, scriptContent: string | null };
            try {
                const response = await Api.requestText(`/api/staticfiles/declaration/${script.name}?updated=${new Date()}`);
                declarations = {
                    name: script.name, 
                    importScriptUrl: script.importScriptUrl, 
                    appId: script.appId, 
                    scriptContent: response
                }
            } catch (ex) {
                console.log(ex);
            }

            return declarations!;
        }

        let response: Array<{name: string, importScriptUrl: string, appId?: string, scriptContent: string | null}> = new Array();

        if (appScripts.length > 0) {
            response = await sendRequest(
                appScripts,
                'stbv_O365_AppsFiles',
                `[App_ID] = '${this.options.appId}' AND [ID] IN (${appScripts.map((appScript) => `'${appScript.name}'`).join(', ')})`
            );
        }

        if (siteScripts.length > 0) {
            if([113504].includes(userSession.personId ?? -1)) {
                const requests = [];
                for (const script of siteScripts) {
                    const request = sendDeclarationRequest(script);
                    requests.push(request);
                }
                await Promise.all(requests).then(data => {
                    response = data;
                });
            } else {
                response = await sendRequest(
                    siteScripts,
                    'stbv_O365_StaticFiles',
                    `[ID] IN (${siteScripts.map((siteScript) => `'${siteScript.name}'`).join(', ')})`
                ); 
            }
            
            
        }

        for (const script of response) {
            await this.storeScriptContentToDatabase(script.name, script.importScriptUrl, script.scriptContent ?? '', script.appId);
        }

        return response;
    }

    private async storeScriptContentToDatabase(name: string, importScriptUrl: string, scriptContent: string, appId?: string): Promise<void> {
        localStorageHelper.setItem(`monaco-editor-intellisense-${appId ?? 'site'}-${name}`, importScriptUrl, { global: true });

        const cache = await caches.open('monaco-editor-intellisense-scripts');

        await cache.put(`/api/dev/monaco-editor/intellisense/${appId ?? 'site'}/${name}`, new Response(scriptContent, {
            status: 200,
            statusText: 'OK'
        }));
    }

    private _getErrorAction(model, error, title, newText, isPreferred) : any {
        return {
            title: title,
            diagnostics: [error],
            kind: "quickfix",
            edit: {
                edits: [
                    {
                        resource: model.uri,
                        textEdit:
                            {
                                range: error,
                                text: newText
                            }
                    }
                ]
            },
            isPreferred: isPreferred
        }
    }

    private _checkForCustomWarningsHandle : number | undefined;
    private _checkForCustomWarnings(model) {
        const markers = [];
        const checks = [
            {regex: /\bfetch\b/g, warning: "Use API.request instead of fetch. import API from 'o365.modules.data.api.ts'"},
            {regex: /\$t\(`.*?`\)/g, warning: "$t(`...`) will not be added to localized-strings"}
            ];

        for (let i = 1; i <= model.getLineCount(); i++) {
            const content = model.getLineContent(i);

            let match;
            for (const check of checks)
            {
                while ((match = check.regex.exec(content)) !== null) {
                    markers.push({
                        severity: monaco.MarkerSeverity.Warning,
                        message: check.warning,
                        startLineNumber: i,
                        startColumn: match.index + 1,
                        endLineNumber: i,
                        endColumn: match.index + match[0].length + 1,
                        code: match[0]
                    });
                }
            }
        }

        monaco.editor.setModelMarkers(model, 'fetchMarker', markers);
    }
}

document.body.addEventListener('o365.theme.changed', (event) => {
    if (event.detail.theme === 'dark') {
        editor.setTheme('vs-dark')
    } else {
        editor.setTheme('vs')
    }
});
